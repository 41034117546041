import type { News } from "../News.js";
import { getPathInLocale } from "./getPathInLocale.js";
import { newsPathMap } from "./newsPathMap.js";

export function getNewsDetailPath(news: Pick<News, "locale" | "urlSlug">, inLocale = true): string {
	const path = `${newsPathMap[news.locale]}/${news.urlSlug}`;
	if (inLocale) {
		return getPathInLocale(news.locale, path);
	} else {
		return path;
	}
}
